import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Helper, {
  convertDateFormat,
  convertMobileFormat,
  convertIdCardFormat,
} from "../../../services/helper";

import HeaderPageTitle from "../../../layouts/inc/HeaderPageTitle";

import img_icoEdit from "../../../assets/img/ci/profile/ico-edit.svg";
import img_imgProfile from "../../../assets/img/ci/profile/img-profile.png";

const ProfileDetail = () => {
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (Object.getOwnPropertyNames(stateProfile.dataUser).length === 0) {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pageProfileDetail">
        <main>
          <div className="wrapContent">
            <HeaderPageTitle title="โปรไฟล์" prevPage="/profile" />

            <section id="pProfileDetail">
              <div className="bProfileDetail">
                <div className="bTitle">
                  <div
                    className="bBtn"
                    onClick={handleGotoPage}
                    data-page="edit-profile"
                  >
                    <img src={img_icoEdit} alt="" />
                    <a href="#">แก้ไข</a>
                  </div>
                  <div className="user-detail">
                    <div className="bProfile-picture">
                      {/* <img className="profile" src={img_imgProfile} alt="" /> */}
                      <img className="profile" src={stateProfile.lineImgUrl} alt="" />
                    </div>
                    <p>{stateProfile.dataUser.fullname}</p>
                    <p>ID: {stateProfile.id}</p>
                  </div>
                </div>

                <div className="bForm">
                  <div className="bRow">
                    <div className="control-group">
                      <div className="tTitle">ชื่อ</div>
                      <p>{stateProfile.dataUser.fullname}</p>
                    </div>
                  </div>
                  <div className="bRow">
                    <div className="control-group">
                      <div className="tTitle">เบอร์โทรศัพท์</div>
                      <p>
                        {convertMobileFormat(
                          stateProfile.dataUser.mobileNo,
                          "-"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="bRow">
                    <div className="control-group">
                      <div className="tTitle">หมายเลขบัตรประชาชน</div>
                      <p>
                        {convertIdCardFormat(stateProfile.dataUser.idCard, "-")}
                      </p>
                    </div>
                  </div>
                  <div className="bRow">
                    <div className="control-group">
                      <div className="tTitle">อีเมล</div>
                      <p>{stateProfile.dataUser.email ? stateProfile.dataUser.email : ""}</p>
                    </div>
                  </div>
                  <div className="bRow">
                    <div className="control-group">
                      <div className="tTitle">วัน / เดือน / ปีเกิด</div>
                      <p>
                        {convertDateFormat(
                          stateProfile.dataUser.birthDate,
                          "d/m/y"
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="bRow">
                    <div className="control-group">
                      <div className="tTitle">เพศ</div>
                      <p>
                        {stateProfile.dataUser.gender === "M"
                          ? "ชาย"
                          : stateProfile.dataUser.gender === "F"
                          ? "หญิง"
                          : "ไม่ระบุ"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default ProfileDetail;
