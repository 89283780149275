import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setProfile } from "../../../features/ProfileSlice";
import { PatternFormat } from "react-number-format";
import {
  searchCustomer,
  updateCustomerProfile,
} from "../../../services/Api/Module/Customer";

import Helper, {
  convertDateFormat,
  convertMobileFormat,
  convertIdCardFormat,
  breakKeyDownEnter,
} from "../../../services/helper";

// import HeaderPageTitle from "../../../layouts/inc/HeaderPageTitle";

import ModalEditProfileCancel from "../../../components/modal/ModalEditProfileCancel";
import ModalEditProfileSuccess from "../../../components/modal/ModalEditProfileSuccess";

import img_icoBack from "../../../assets/img/ci/header/ico-back.svg";
import img_imgProfile from "../../../assets/img/ci/profile/img-profile.png";

const EditProfile = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataProfile = (newData) => {
    const tmpObj = { dataUser: newData };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const navigate = useNavigate();

  /* Form */
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getFieldState,
  } = useForm({
    defaultValues: {
      fullname: "",
      // mobileNo: "",
      // idCard: "",
      // email: "",
      birthDate: "",
      gender: "",
    },
  });

  // console.log("isValid: ", isValid);
  const [formIsValid, setFormIsValid] = useState(false);

  const handleCheckOnChange = (_ele = "") => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }

      // if (key === "mobileNo") {
      //   let _value = val;
      //   _value = _value.replaceAll("-", "");
      //   _value = _value.trim();

      //   // console.log(_value, _value.length);

      //   if (_value.length === 10) {
      //     if (_ele === key) {
      //       _invalid = false;

      //       handleCheckDataExists(_ele, _value);
      //     }
      //   } else {
      //     _invalid = true;
      //   }
      // }

      // if (key === "idCard") {
      //   let _value = val;
      //   _value = _value.replaceAll("-", "");
      //   _value = _value.trim();

      //   // console.log(_value, _value.length);

      //   if (_value.length === 13) {
      //     if (_ele === key) {
      //       _invalid = false;

      //       handleCheckDataExists(_ele, _value);
      //     }
      //   } else {
      //     _invalid = true;
      //   }
      // }

      // if (key === "email") {
      //   let _value = val;
      //   _value = _value.trim();

      //   const _regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      //   // console.log(_value, _regexEmail.test(_value));

      //   if (_regexEmail.test(_value)) {
      //     if (_ele === key) {
      //       _invalid = false;

      //       handleCheckDataExists(_ele, _value);
      //     }
      //   } else {
      //     _invalid = true;
      //   }
      // }

      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log(formIsValid);
  }, [formIsValid]);
  /* End Form */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // console.log("onSubmit >>", data);

    data.mobileNo = convertMobileFormat(data.mobileNo);
    data.idCard = convertIdCardFormat(data.idCard);

    const payload = {
      data: data,
      custId: stateProfile.id,
    };
    // console.log(payload);

    putUpdateCustomer(payload).then((_rsData) => {
      // console.log(_rsData);

      if (_rsData.status === true) {
        setDataProfile({
          ...stateProfile.dataUser,
          ...data,
        });

        setIsModalEditProfileSuccessShow(true);
      }
    });
  };

  const putUpdateCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateCustomerProfile({
        data: payload.data,
        custId: payload.custId,
      });
      // console.log("data.body >>", data);
      if (data.resultCode === 20202) {
        const resData = data.body;
        // console.log("putUpdateCustomer resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        console.warn(data);
      }
    } catch (e) {
      console.log("ERR putUpdateCustomer >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* Check data exists real-time on server-side  */
  const handleCheckDataExists = (field, value) => {
    // console.log(field, value);

    clearErrors(field);

    let payload = {
      q: "register",
    };

    if (field === "mobileNo") {
      payload = {
        ...payload,
        mobileNo: value,
      };
    } else if (field === "idCard") {
      payload = {
        ...payload,
        idCard: value,
      };
    } else if (field === "email") {
      payload = {
        ...payload,
        email: value,
      };
    }

    getSearchCustomer(payload);
  };

  const getSearchCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await searchCustomer({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getSearchCustomer resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        if (data.resultCode === 40301) {
          setFormIsValid(false);

          if (payload.mobileNo) {
            setError(
              "mobileNo",
              {
                type: "manual",
                message: `หมายเลขโทรศัพท์นี้มีผู้ใช้งานแล้ว`,
              },
              {
                shouldFocus: true,
              }
            );
          } else if (payload.idCard) {
            setError(
              "idCard",
              {
                type: "manual",
                message: `หมายเลขบัตรประชาชนนี้มีผู้ใช้งานแล้ว`,
              },
              {
                shouldFocus: true,
              }
            );
          } else if (payload.email) {
            setError(
              "email",
              {
                type: "manual",
                message: `อีเมลนี้มีผู้ใช้งานแล้ว`,
              },
              {
                shouldFocus: true,
              }
            );
          }
        }
      }
    } catch (e) {
      console.log("ERR getSearchCustomer >> ", e);
    }

    return _return;
  };
  /* End Check data exists real-time on server-side  */

  /* Modal edit profile cancel */
  const [isModalEditProfileCancelShow, setIsModalEditProfileCancelShow] =
    useState(false);

  const handleCloseModalEditProfileCancel = (editProfileCancel) => {
    setIsModalEditProfileCancelShow(false);

    if (editProfileCancel === true) {
      setTimeout(() => navigate("/profile-detail"), 200);
    }
  };
  /* End Modal edit profile cancel */

  /* Modal edit profile success */
  const [isModalEditProfileSuccessShow, setIsModalEditProfileSuccessShow] =
    useState(false);

  const handleCloseModalEditProfileSuccess = (editProfileSuccess) => {
    setIsModalEditProfileSuccessShow(false);

    if (editProfileSuccess === true) {
      setTimeout(() => navigate("/profile-detail"), 200);
    }
  };
  /* End Modal edit profile success */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (Object.getOwnPropertyNames(stateProfile.dataUser).length === 0) {
      navigate("/");
    } else {
      const { dataUser } = stateProfile;

      setValue("fullname", dataUser.fullname);
      setValue("mobileNo", convertMobileFormat(dataUser.mobileNo, "-"));
      setValue("idCard", convertIdCardFormat(dataUser.idCard, "-"));
      setValue("email", dataUser.email);
      setValue("birthDate", convertDateFormat(dataUser.birthDate));
      setValue("gender", dataUser.gender);

      let _chkFormIsValid = true;
      const _chkField = [
        "fullname",
        // "mobileNo",
        // "idCard",
        // "email",
        "birthDate",
        "gender",
      ];
      Object.entries(dataUser).forEach(([key, val]) => {
        if (_chkField.includes(key)) {
          if (val === "" || val === false || val === null) {
            _chkFormIsValid = false;
          }
        }
      });

      if (_chkFormIsValid === true) {
        setFormIsValid(true);
      }
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pageEditProfile">
        <main>
          <div className="wrapContent">
            <section id="pTiltlePage">
              <div className="bTitle">
                <div className="bText">
                  <a
                    href="#"
                    onClick={() => setIsModalEditProfileCancelShow(true)}
                  >
                    <img src={img_icoBack} alt="" />
                  </a>
                  <h2>โปรไฟล์</h2>
                  <div className="empty"></div>
                </div>
              </div>
            </section>

            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pEditProfile">
                <div className="bEditProfile">
                  <div className="bTitle">
                    <div className="bProfile-picture">
                      {/* <img src={img_imgProfile} alt="" /> */}
                      <img src={stateProfile.lineImgUrl} alt="" />
                    </div>
                    <div className="tName">
                      <p>{stateProfile.dataUser.fullname}</p>
                      <p>ID: {stateProfile.id}</p>
                    </div>
                  </div>

                  <div className="bForm">
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.fullname ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">ชื่อ-นามสกุล</div>
                        <Controller
                          name="fullname"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกชื่อ-นามสกุล`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="text"
                              placeholder={`ชื่อและนามสกุลของคุณ`}
                              onInput={Helper.FNFORM.handleCharOnly}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.fullname && (
                          <label htmlFor="" className="tError">
                            {errors.fullname.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.mobileNo ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">เบอร์โทรศัพท์</div>
                        {/* <Controller
                          name="mobileNo"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกเบอร์โทรศัพท์`,
                            pattern: {
                              value: /^\d{3}-\d{3}-\d{4}$/, // /^\d{10}$/,
                              message: `กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง`,
                            },
                          }}
                          render={({ field: { name, value, onChange } }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              displayType="input"
                              format="###-###-####"
                              placeholder={`กรอกเบอร์โทรศัพท์`}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange(name);
                              }}
                              disabled="disabled"
                              readOnly="readonly"
                            />
                          )}
                        /> */}
                        <input
                          type="text"
                          value={convertMobileFormat(
                            stateProfile.dataUser.mobileNo,
                            "-"
                          )}
                          disabled
                          readOnly
                        />
                        {errors.mobileNo && (
                          <label htmlFor="" className="tError">
                            {errors.mobileNo.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.idCard ? "error" : ""
                        }`}
                      >
                        {" "}
                        <div className="tTitle">หมายเลขบัตรประชาชน</div>
                        {/* <Controller
                          name="idCard"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกหมายเลขบัตรประชาชน`,
                            pattern: {
                              value: /^\d{1}-\d{4}-\d{5}-\d{2}-\d{1}$/, // /^\d{13}$/,
                              message: `กรุณากรอกหมายเลขบัตรประชาชนให้ถูกต้อง`,
                            },
                          }}
                          render={({ field: { name, value, onChange } }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              displayType="input"
                              format="#-####-#####-##-#"
                              placeholder={`หมายเลขบัตรประชาชนของคุณ`}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange(name);
                              }}
                              disabled="disabled"
                              readOnly="readonly"
                            />
                          )}
                        /> */}
                        <input
                          type="text"
                          value={convertIdCardFormat(
                            stateProfile.dataUser.idCard,
                            "-"
                          )}
                          disabled
                          readOnly
                        />
                        {errors.idCard && (
                          <label htmlFor="" className="tError">
                            {errors.idCard.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.email ? "error" : ""
                        }`}
                      >
                        {" "}
                        <div className="tTitle">อีเมล</div>
                        {/* <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกอีเมล`,
                            pattern: {
                              value:
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                              message: `กรุณากรอกอีเมลให้ถูกต้อง`,
                            },
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="email"
                              placeholder={`กรอกอีเมลของคุณ`}
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange(field.name);
                              }}
                              disabled="disabled"
                              readOnly="readonly"
                            />
                          )}
                        /> */}
                        <input
                          type="text"
                          value={
                            stateProfile.dataUser.email
                              ? stateProfile.dataUser.email
                              : ""
                          }
                          disabled
                          readOnly
                        />
                        {errors.email && (
                          <label htmlFor="" className="tError">
                            {errors.email.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group ${
                          errors.birthDate ? "error" : ""
                        }`}
                      >
                        {" "}
                        <div className="tTitle">วัน/เดือน/ปีเกิด</div>
                        <Controller
                          name="birthDate"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกวัน/เดือน/ปี ค.ศ. ที่เกิด`,
                          }}
                          render={({ field: { onChange, ...field } }) => (
                            <input
                              {...field}
                              type="date"
                              onChange={({ target: { value } }) => {
                                onChange(value);
                                handleCheckOnChange();
                              }}
                            />
                          )}
                        />
                        {errors.birthDate && (
                          <label htmlFor="" className="tError">
                            {errors.birthDate.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow radio">
                      <div
                        className={`control-group ${
                          errors.gender ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">เพศ</div>
                        <Controller
                          name="gender"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณาเลือกเพศ`,
                          }}
                          render={({ field }) => (
                            <div className="tChoice">
                              <label className="control control--radio">
                                ชาย
                                <input
                                  {...field}
                                  type="radio"
                                  value="M"
                                  checked={field.value === "M"}
                                />
                                <div className="control__indicator"></div>
                              </label>
                              <label className="control control--radio">
                                หญิง
                                <input
                                  {...field}
                                  type="radio"
                                  value="F"
                                  checked={field.value === "F"}
                                />
                                <div className="control__indicator"></div>
                              </label>
                              <label className="control control--radio">
                                ไม่ระบุ
                                <input
                                  {...field}
                                  type="radio"
                                  value="O"
                                  checked={field.value === "O"}
                                />
                                <div className="control__indicator"></div>
                              </label>
                            </div>
                          )}
                        />
                        {errors.gender && (
                          <label htmlFor="" className="tError">
                            {errors.gender.message}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn btnConfirmEdit"
                    type="submit"
                    disabled={formIsValid ? false : true}
                  >
                    บันทึก
                  </button>
                </div>
              </section>
            </form>
          </div>
          <ModalEditProfileSuccess
            open={isModalEditProfileSuccessShow}
            onClose={(editProfileSuccess) =>
              handleCloseModalEditProfileSuccess(editProfileSuccess)
            }
          />

          <ModalEditProfileCancel
            open={isModalEditProfileCancelShow}
            onClose={(editProfileCancel) =>
              handleCloseModalEditProfileCancel(editProfileCancel)
            }
          />
        </main>
      </div>
    </>
  );
};

export default EditProfile;
