import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setProfile } from "../../../features/ProfileSlice";
import {
  survey,
  createCustomer,
  updateCustomerVerify,
  custProfile,
} from "../../../services/Api/Module/Customer";

import HeaderPageTitle from "../../../layouts/inc/HeaderPageTitle";

import ModalContext from "../../../contexts/ModalContext";

import { breakKeyDownEnter } from "../../../services/helper";

import img_icoTitleLogo from "../../../assets/img/ci/ico-title-logo.svg";

const Questionnaire = () => {
  const { modalAlert } = useContext(ModalContext); // import modal

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  const navigate = useNavigate();

  /* Form */
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm();

  // console.log("isValid: ", isValid);
  /* End Form */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // console.log("onSubmit >>", data);

    let updateData = {
      lineUid: stateProfile.lineUid,
      imgLineUrl: stateProfile.lineImgUrl,
      isVerified: true,
      survey: [],
    };

    Object.entries(data.q).forEach(([key, val]) => {
      const tmpData = {
        questionId: key,
        answerList: Array.isArray(val) ? val : [val],
      };

      updateData.survey.push(tmpData);
    });

    let payload = {
      ...stateRegister.dataUser,
      ...updateData,
      tpdDataExist: false,
    };

    // console.log(stateRegister, payload);

    /*** Verify customer ***/
    if (stateRegister.flow === "verify") {
      if (typeof payload.id === "undefined" || payload.id === null) {
        payload.tpdDataExist = true;

        postCreateCustomer(payload).then((_rsData) => {
          // console.log("_rsData >>", _rsData);

          if (_rsData.status === true) {
            setDataProfile(_rsData.result);
          } else {
            console.warn(_rsData);
          }
        });
      } else {
        postUpdateCustomer(payload).then((_rsData) => {
          // console.log(_rsData);

          if (_rsData.status === true) {
            setDataProfile(_rsData.result);
          } else {
            console.warn(_rsData);
          }
        });
      }
    } else {
      postCreateCustomer(payload).then((_rsData) => {
        // console.log("_rsData >>", _rsData);

        if (_rsData.status === true) {
          setDataProfile(_rsData.result);
        } else {
          console.warn(_rsData);
        }
      });
    }
  };

  const setDataProfile = (newData = {}) => {
    const tmpObj = {
      id: newData.id,
      custCode: newData.custCode,
      dataUser: newData,
    };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const postCreateCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await createCustomer({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20201) {
        const resData = data.body;
        // console.log("postCreateCustomer resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = `เกิดข้อผิดพลาด`,
          _subTitle = ``,
          _content = `กรุณาลองใหม่อีกครั้ง`;

        // if (data.resultCode === 40400) {
        //   _title = "";
        //   _subTitle = "";
        //   _content = "";
        // }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: _content,
        });
      }
    } catch (e) {
      console.log("ERR postCreateCustomer >> ", e);
    }

    return _return;
  };

  const postUpdateCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await updateCustomerVerify({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20202) {
        const resData = data.body;
        // console.log("postUpdateCustomer resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = `เกิดข้อผิดพลาด`,
          _subTitle = ``,
          _content = `กรุณาลองใหม่อีกครั้ง`;

        // if (data.resultCode === 40400) {
        //   _title = "";
        //   _subTitle = "";
        //   _content = "";
        // }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
        });
      }
    } catch (e) {
      console.log("ERR postUpdateCustomer >> ", e);
    }

    return _return;
  };

  const getCustProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await custProfile({
        params: payload.params,
        custId: payload.custId,
      });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCustProfile resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustProfile >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* First event render */
  const [question, setQuestion] = useState([]);

  const handleGetQuestion = () => {
    getSurvey({}).then((_rsDataSurvey) => {
      // console.log(_rsDataSurvey);

      if (
        _rsDataSurvey.result !== null &&
        Object.keys(_rsDataSurvey.result).length !== 0
      ) {
        setQuestion(_rsDataSurvey.result);
      }
    });
  };

  const getSurvey = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await survey({ params: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getSurvey resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        console.warn(data);
      }
    } catch (e) {
      console.log("ERR getSurvey >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log(question);
  }, [question]);

  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    // if (stateProfile.lineUid !== null && stateProfile.custCode !== null) {
    //   navigate("/profile");
    // }

    const { dataUser } = stateRegister;
    if (Object.keys(dataUser).length !== 0) {
      handleGetQuestion();
    } else {
      navigate("/");
    }
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log(stateProfile);

    if (typeof stateProfile.dataUser.id !== "undefined") {
      navigate("/welcome");
    }
  }, [stateProfile]);

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pageQuestionnaire">
        <main>
          <div className="wrapContent">
            <HeaderPageTitle title="แบบสอบถาม" />

            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pQuestionMain">
                <div className="bQuestion">
                  <div className="bTitle">
                    <img src={img_icoTitleLogo} alt="" />
                    <div className="pageStep question">
                      <div className="step active"></div>
                      <div className="step active"></div>
                      <div className="step active"></div>
                    </div>
                    <p>ลงทะเบียนขั้นตอนสุดท้ายแล้ว!</p>
                  </div>

                  <div className="bForm">
                    <div className="bRow">
                      <div className="control-group question">
                        <p className="tTitle">
                          ตอบคำถามกับเรา เพื่อให้เราได้เข้าใจคุณมากขึ้น
                        </p>
                        {question.length > 0 &&
                          question.map((questionVal, questionIdx) => (
                            <div
                              className="bQuestionnaire"
                              key={questionVal.questionId}
                            >
                              <p className="tQuestion">
                                {`${questionIdx + 1}. ${
                                  questionVal.questionName
                                }`}
                              </p>

                              {questionVal.isMultiAnswer === 1
                                ? questionVal.answerList.map(
                                    (answer_val, answer_idx) => (
                                      <label
                                        className="control control--checkbox"
                                        key={answer_val.id}
                                      >
                                        <p className="">{answer_val.name}</p>
                                        <input
                                          {...register(
                                            `q.${questionVal.questionId}`,
                                            { required: true }
                                          )}
                                          type="checkbox"
                                          value={answer_val.id}
                                        />
                                        <div className="control__indicator"></div>
                                      </label>
                                    )
                                  )
                                : questionVal.answerList.map(
                                    (answer_val, answer_idx) => (
                                      <label
                                        className="control control--radio"
                                        key={answer_val.id}
                                      >
                                        <p className="">{answer_val.name}</p>
                                        <input
                                          {...register(
                                            `q.${questionVal.questionId}`,
                                            { required: true }
                                          )}
                                          type="radio"
                                          value={answer_val.id}
                                        />
                                        <div className="control__indicator"></div>
                                      </label>
                                    )
                                  )}
                            </div>
                          ))}
                      </div>
                    </div>

                    {/* <p className="tError">Text Error Main Form</p> */}
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn"
                    type="submit"
                    disabled={isValid ? false : true}
                  >
                    ยืนยัน
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default Questionnaire;
