import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import img_icoBack from "../../assets/img/ci/header/ico-back.svg";

function HeaderPageTitle(props) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(props.prevPage === "-1" ? -1 : props.prevPage);
  };

  /* First event render */
  // useEffect(() => {
  //   console.log(stateProfile.lineUid);
  // }, []);
  /* End First event render */

  return (
    <>
      <section id="pTiltlePage">
        <div className="bTitle">
          <div className="bText">
            {props.prevPage ? (
              <a onClick={goBack}>
                <img src={img_icoBack} alt="" />
              </a>
            ) : (
              <div></div>
            )}
            {props.title && <h2>{props.title}</h2>}
            {props.prevPage ? <div className="empty"></div> : <div></div>}
          </div>
        </div>
      </section>
    </>
  );
}
export default HeaderPageTitle;
