import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import HeaderPageTitle from "../../../layouts/inc/HeaderPageTitle";

const MemberList = () => {
  // const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  const [memberPointAll, setMemberPointAll] = useState([]);

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (Object.getOwnPropertyNames(stateProfile.dataUser).length === 0) {
      navigate("/");
    } else {
      if (
        typeof stateProfile.dataUser.memberPoint !== "undefined" &&
        typeof stateProfile.dataUser.memberPoint["all"] !== "undefined"
      ) {
        setMemberPointAll(stateProfile.dataUser.memberPoint["all"]);
      }
    }
  }, []);
  /* End First event render */

  // useEffect(() => {
  //   console.log(memberPointAll);
  // }, [memberPointAll]);

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pagePoint">
        <main>
          <div className="wrapContent">
            <HeaderPageTitle title="พอยท์ของฉัน" prevPage="/member" />

            <section id="pMembercardMain">
              <div className="bMembercard">
                <div className="titleProfile">
                  <div className="bUserDetail">
                    <div className="bDtail">
                      <div className="bImg">
                        <img src={stateProfile.lineImgUrl} alt="" />
                      </div>
                      <div className="tName">
                        <p>ยินดีต้อนรับ</p>
                        <p>{stateProfile.dataUser.fullname}</p>
                      </div>
                    </div>
                    <div className="bBtn">
                      <a href="#" onClick={handleGotoPage} data-page="profile">
                        ดูโปรไฟล์
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="pMemberPoint">
              <div className="bTitle">
                <h2>รายการเมมเบอร์</h2>
                <p>พอยท์ที่แสดงอิงจากพอยท์ต้นทางของเมมเบอร์</p>
              </div>
              <div className="bMemberList">
                {/* <?php for ($y = 1; $y <= 4; $y++) {?> */}
                {/* <article>
                  <div className="bLeft">
                    <div className="bPic">
                      <img
                        src="https://dummyimage.com/200x200/667085/fff"
                        alt=""
                      />
                    </div>
                    <div className="bName">
                      <h3>Member Name</h3>
                      <p>ST12345678</p>
                    </div>
                  </div>
                  <div className="bPoint">
                    <p>
                      <span>9,999,999</span>
                      พอยท์
                    </p>
                  </div>
                </article> */}
                {/* <?php };?> */}
                {memberPointAll.length > 0 ? (
                  memberPointAll.map((val, idx) => (
                    <article>
                      <div className="bLeft">
                        <div className="bPic">
                          <img src={val.imageFileUrl} alt="" />
                        </div>
                        <div className="bName">
                          <h3>{val.name}</h3>
                          <p>{val.memberId}</p>
                        </div>
                      </div>
                      <div className="bPoint">
                        <p>
                          <span>{parseInt(val.point).toLocaleString()}</span>
                          พอยท์
                        </p>
                      </div>
                    </article>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default MemberList;
