import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setProfile } from "../../../features/ProfileSlice";
import { custProfile } from "../../../services/Api/Module/Customer";

import Helper, {
  convertDateFormat,
  convertMobileFormat,
} from "../../../services/helper";

// import useTranslations from "../../../i18n/useTranslations";

import img_imgUser from "../../../assets/img/ci/profile/img-user.png";
import img_icoSearch from "../../../assets/img/ci/profile/ico-search.svg";
import img_icoTop1 from "../../../assets/img/ci/profile/ico-top-1.svg";
import img_icoTop2 from "../../../assets/img/ci/profile/ico-top-1.svg";
import img_icoTop3 from "../../../assets/img/ci/profile/ico-top-1.svg";
import img_icoAppLogo1 from "../../../assets/img/ci/profile/ico-app-logo1.svg";
import img_icoAppLogo2 from "../../../assets/img/ci/profile/ico-app-logo2.svg";
import img_icoAppLogo3 from "../../../assets/img/ci/profile/ico-app-logo3.svg";
import img_icoAppLogo4 from "../../../assets/img/ci/profile/ico-app-logo4.svg";
import img_icoAppLogo5 from "../../../assets/img/ci/profile/ico-app-logo5.svg";
import img_icoAppLogo6 from "../../../assets/img/ci/profile/ico-app-logo6.svg";
import img_icoAppLogo7 from "../../../assets/img/ci/profile/ico-app-logo7.svg";

const Member = () => {
  // const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateProfile = useSelector((state) => state.profile);

  const setDataProfile = (dataUser) => {
    const tmpObj = { dataUser: dataUser };
    dispatch(setProfile(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Handle goto page */
  const navigate = useNavigate();

  const handleGotoPage = (e) => {
    e.preventDefault();
    // console.log("click GotoPage", e.currentTarget.dataset.page);

    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Handle goto page */

  /* First event render */
  const [memberPointTop3, setMemberPointTop3] = useState([]);

  const getCustProfile = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await custProfile({
        params: payload.params,
        custId: payload.custId,
      });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("getCustProfile resData >>", resData);

        _return.status = true;
        _return.result = resData;
      }
    } catch (e) {
      console.log("ERR getCustProfile >> ", e);
    }

    return _return;
  };

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (
      stateProfile.id === 0 &&
      Object.getOwnPropertyNames(stateProfile.dataUser).length === 0
    ) {
      navigate("/");
    } else {
      if (
        typeof stateProfile.dataUser.memberPoint !== "undefined" &&
        typeof stateProfile.dataUser.memberPoint["top3"] !== "undefined"
      ) {
        setMemberPointTop3(stateProfile.dataUser.memberPoint["top3"]);
      }

      getCustProfile({
        params: {},
        custId: stateProfile.id,
      }).then((_rsData) => {
        // console.log("_rsData >>", _rsData);

        if (_rsData.status === true) {
          setDataProfile(_rsData.result);

          if (_rsData.result.memberPoint["top3"].length > 0) {
            setMemberPointTop3(_rsData.result.memberPoint["top3"]);
          }
        }
      });
    }
  }, []);
  /* End First event render */

  // useEffect(() => {
  //   console.log(memberPointTop3);
  // }, [memberPointTop3]);

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pageMember">
        <main>
          <div className="wrapContent">
            <section id="pMembercardMain">
              <div className="bMembercard">
                <div className="titleProfile">
                  <div className="bUserDetail">
                    <div className="bDtail">
                      <div className="bImg">
                        <img src={stateProfile.lineImgUrl} alt="" />
                      </div>
                      <div className="tName">
                        <p>ยินดีต้อนรับ</p>
                        <p>{stateProfile.dataUser.fullname}</p>
                      </div>
                    </div>
                    <div className="bBtn">
                      <a href="#" onClick={handleGotoPage} data-page="profile">
                        ดูโปรไฟล์
                      </a>
                    </div>
                  </div>
                  {memberPointTop3.length > 0 ? (
                    <div className="bPoint">
                      <p>พอยท์ที่แสดงอิงจากพอยท์ต้นทางของเมมเบอร์</p>
                      <div className="bTopPoint">
                        {memberPointTop3.map((val, idx) => (
                          <div className="bPointContent" key={idx + 1}>
                            <img src={val.imageFileUrl} alt="" />
                            <p className="tName">#{`${idx + 1} ${val.name}`}</p>
                            <p className="tPoint">
                              {parseInt(val.point).toLocaleString()}
                            </p>
                            <p>พอยท์</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="bCard">
                      <div className="bCardContent">
                        <img src={img_icoSearch} alt="" />
                        <p>ไม่มีรายการเมมเบอร์</p>
                        <p>
                          ไปยัง<a>เว็บไซต์ของเรา</a>เพื่อเพิ่มเมมเบอร์
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="bPoint-btn">
                <a onClick={handleGotoPage} data-page="member-list">
                  ดูพอยท์ของฉัน
                </a>
              </div>
            </section>
            <section id="pMemberPayPoint">
              <p>เมมเบอร์ Paypoint</p>

              <div className="bGroupApp">
                <div className="bApp">
                  <img src={img_icoAppLogo1} alt="" />
                  <p>AIR ASIA</p>
                </div>
                <div className="bApp">
                  <img src={img_icoAppLogo2} alt="" />
                  <p>Health Up</p>
                </div>
                <div className="bApp">
                  <img src={img_icoAppLogo3} alt="" />
                  <p>IMPACT...</p>
                </div>
                <div className="bApp">
                  <img src={img_icoAppLogo4} alt="" />
                  <p>KTC</p>
                </div>
                <div className="bApp">
                  <img src={img_icoAppLogo5} alt="" />
                  <p>G99 Golden</p>
                </div>
                <div className="bApp">
                  <img src={img_icoAppLogo6} alt="" />
                  <p>Bangchak</p>
                </div>
                <div className="bApp">
                  <img src={img_icoAppLogo7} alt="" />
                  <p>PRINCIPAL</p>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Member;
