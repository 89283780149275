import React, {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import HomePage from "./pages/home/index";

import AuthUserPage from "./pages/account/auth/index";
import AuthConfirmPage from "./pages/account/auth/confirm";
import AuthOtpPage from "./pages/account/auth/otp";
import RegisterUserPage from "./pages/account/register/index";

import QuestionnairePage from "./pages/account/questionnaire/index";

import WelcomePage from "./pages/account/welcome/index";
import MemberPage from "./pages/account/member/index";
import MemberListPage from "./pages/account/member/list";
import ProfilePage from "./pages/account/profile/index";
import ProfileDetailPage from "./pages/account/profile/detail";
import EditProfilePage from "./pages/account/profile/edit";

// import BuyHistoryPage from "./pages/account/history/buy";
// import ReturnHistoryPage from "./pages/account/history/return";

import TermAndConditionsPage from "./pages/consent/termAndConditions";
import PrivacyPolicyPage from "./pages/consent/privacyPolicy";

const appRouter = (props) => {
  // console.log(props.isHasProfile, props.profileData);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              props.isHasProfile ? <Navigate to="/member" /> : <HomePage />
            }
          />

          <Route
            path="/home"
            element={
              props.isHasProfile ? <Navigate to="/member" /> : <HomePage />
            }
          />

          <Route
            path="/auth-user"
            element={
              props.isHasProfile ? <Navigate to="/member" /> : <AuthUserPage />
            }
          />

          <Route
            path="/auth-confirm"
            element={
              props.isHasProfile ? (
                <Navigate to="/member" />
              ) : (
                <AuthConfirmPage />
              )
            }
          />

          <Route
            path="/register"
            element={
              props.isHasProfile ? (
                <Navigate to="/member" />
              ) : (
                <RegisterUserPage />
              )
            }
          />

          <Route
            path="/auth-otp"
            element={
              props.isHasProfile ? <Navigate to="/member" /> : <AuthOtpPage />
            }
          />

          <Route
            path="/questionnaire"
            element={
              props.isHasProfile ? (
                <Navigate to="/member" />
              ) : (
                <QuestionnairePage />
              )
            }
          />

          <Route path="/welcome" element={<WelcomePage />} />

          <Route path="/member" element={<MemberPage />} />
          <Route path="/member-list" element={<MemberListPage />} />

          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/profile-detail" element={<ProfileDetailPage />} />
          <Route path="/edit-profile" element={<EditProfilePage />} />

          {/* <Route path="/history" element={<Navigate to="/history/buy" />} /> */}
          {/* <Route path="/history/buy" element={<BuyHistoryPage />} /> */}
          {/* <Route path="/history/return" element={<ReturnHistoryPage />} /> */}

          <Route
            path="/term-and-conditions"
            element={<TermAndConditionsPage />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

          {/* Not found 404 */}
          <Route
            path="*"
            exact={true}
            // element={<Navigate replace to="/404" />}
            element={<Navigate replace to="/" />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default appRouter;
