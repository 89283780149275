import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";
import { OtpRequest, OtpVerify } from "../../../services/Api/Module/Customer";

import { convertMobileFormat } from "../../../services/helper";

import ModalContext from "../../../contexts/ModalContext";

// import useTranslations from "../../../i18n/useTranslations";

import HeaderPageTitle from "../../../layouts/inc/HeaderPageTitle";

import ModalOtpSuccess from "../../../components/modal/ModalOtpSuccess";

import img_icoOtp from "../../../assets/img/ci/otp/ico-otp.svg";

const AuthOtp = () => {
  const { modalAlert } = useContext(ModalContext); // import modal

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);
  // console.log(stateProfile);

  // const { t } = useTranslations(); // แปลภาษา

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("auth-otp");
  const [step, setStep] = useState({});

  const PageRoute = {
    register: "/register",
    "auth-user": "/auth-user",
    questionnaire: "/questionnaire",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = () => {
    setPrevPage(page);

    if (stateRegister.prevPage === "auth-confirm") {
      navigate(PageRoute["auth-user"]);
    } else {
      navigate(PageRoute["register"]);
    }
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Handle OTP input */
  const [otp, setOtp] = useState("");
  // const [refOtp, setRefOtp] = useState("");

  async function handlePasteOtp(e) {
    // console.log("handlePasteOtp e >>", e.clipboardData.getData("text"));
    const textClipboard = e.clipboardData.getData("text");
    let maxLength = 6;

    if (textClipboard) {
      const smsCodeElements = document.querySelectorAll(".smsCode");

      for (let i = 0; i < maxLength; i++) {
        if (textClipboard[i]) {
          smsCodeElements[i].value = textClipboard[i];
        }
      }
      if (textClipboard.length) {
        if (textClipboard.length < maxLength) {
          smsCodeElements[textClipboard.length - 1].focus();
        } else {
          smsCodeElements[maxLength - 1].focus();
        }
      }
    }
  }
  async function handleInputOtp(e) {
    // console.log("handleInputOtp!!", e);

    const target = e.target;
    const val = target.value;

    if (isNaN(val)) {
      target.value = "";
      return;
    }

    if (val != "") {
      const next = target.nextElementSibling;
      if (next) {
        next.focus();
      }
    }

    setValueOtp();
  }
  function handleKeyupOtp(e) {
    const target = e.target;
    const key = e.key.toLowerCase();

    if (key == "backspace" || key == "delete") {
      target.value = "";
      const prev = target.previousElementSibling;
      if (prev) {
        prev.focus();
      }
      return;
    }
  }

  function setValueOtp() {
    let otpValue = "";
    const smsCodeElements = document.querySelectorAll(".smsCode");
    for (const smsCodeElement of smsCodeElements) {
      // console.log("smsCodeElement.value >>", smsCodeElement.value);
      otpValue += smsCodeElement.value;
    }
    setOtp(otpValue);
  }
  /* End Handle OTP input */

  /* Handle click submit form */
  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(stateRegister);
    // console.log(otp);

    document
      .getElementsByClassName("btn-submit-otp")[0]
      .setAttribute("disabled", "");

    const payloadOtpVerify = {
      token: otpToken,
      pin: otp,
    };

    postOtpVerify(payloadOtpVerify).then((_rsDataOtpVerify) => {
      // console.log(_rsDataOtpVerify);

      if (
        _rsDataOtpVerify.result !== null &&
        _rsDataOtpVerify.result.status === "success"
      ) {
        setDataRegis(
          stateRegister.prevPage === "auth-confirm" ? "verify" : "register"
        );

        setIsModalShow(true);
      } else {
        console.warn(_rsDataOtpVerify);
      }
    });
  };

  const setDataRegis = (flow = null) => {
    const tmpObj = { flow: flow };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const postOtpVerify = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await OtpVerify({ data: payload });
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postOtpVerify resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = "",
          _subTitle = "";

        if (data.resultCode === 40400) {
          switch (data.resultError.code) {
            case "otp_invalid":
              _title = `รหัสผิดพลาด กรุณากรอกใหม่`;
              break;

            case "otp_expired":
              _title = `รหัสหมดอายุ กรุณาส่งรหัสใหม่อีกครั้ง`;
              break;

            default:
              // _title = `${t.otpPage.modalAlertMsg.default.title}`;
              // _subTitle = `${t.otpPage.modalAlertMsg.default.subTitle}`;

              // modalAlert.info({
              //   open: true,
              //   title: _title,
              //   subTitle: _subTitle,
              //   content: "",
              // });
              break;
          }

          if (
            data.resultError.code === "otp_invalid" ||
            data.resultError.code === "otp_expired"
          ) {
            const _form = document.querySelectorAll(
              "#pRegisterMain .bOtp .bFrom"
            );
            _form[0].classList.add("error");

            const _txtError = document.querySelectorAll(
              "#pRegisterMain .bOtp .bFrom .tError"
            );
            _txtError[0].textContent = _title;
          }
        }

        document
          .getElementsByClassName("btn-submit-otp")[0]
          .removeAttribute("disabled");
      }
    } catch (e) {
      console.log("ERR postOtpVerify >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* Run countdonw timer */
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const toggleTimer = () => setRunTimer((t) => !t);

  const seconds = String(countDown % 60).padStart(2, 0);
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

  useEffect(() => {
    let timerInterval;

    if (runTimer) {
      setCountDown(60 * 5);
      // setCountDown(60 * 0.1);

      timerInterval = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("timeout >> expired");

      setCountDown(0);
      setRunTimer(false);
      setIsExpired(true);
    }
  }, [countDown, runTimer, isExpired]);
  /* End Run countdonw timer */

  /* Modal otp verify success */
  const [isModalShow, setIsModalShow] = useState(false);

  const handleCloseModal = () => {
    setIsModalShow(false);

    setTimeout(() => goNext("questionnaire"), 200);
  };
  /* End Modal otp verify success */

  /* First event render */
  const postOtpRequest = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await OtpRequest({ data: payload });
      // console.log("data.body >>", data);
      if (data.resultCode === 20200) {
        const resData = data.body;
        // console.log("postOtpRequest resData >>", resData);

        _return.status = true;
        _return.result = resData;
      } else {
        let _title = `เกิดข้อผิดพลาด`,
          _subTitle = `กรุณาลองใหม่อีกครั้ง`;

        // if (data.resultCode === 40400) {
        //   _title = "";
        //   _subTitle = "";
        // }

        modalAlert.info({
          open: true,
          title: _title,
          subTitle: _subTitle,
          content: "",
        });
      }
    } catch (e) {
      console.log("ERR postOtpRequest >> ", e);
    }

    return _return;
  };

  const [otpToken, setOtpToken] = useState("");
  const [otpRefno, setOtpRefno] = useState("");

  const handleOtpRequest = (mobileNo) => {
    const payloadOtpRequest = {
      msisdn: mobileNo,
    };

    postOtpRequest(payloadOtpRequest).then((_rsDataOtpRequest) => {
      // console.log(_rsDataOtpRequest);

      if (
        _rsDataOtpRequest.result !== null &&
        Object.keys(_rsDataOtpRequest.result).length !== 0
      ) {
        if (
          "status" in _rsDataOtpRequest.result &&
          _rsDataOtpRequest.result.status === "success"
        ) {
          if (runTimer) {
            setCountDown(60 * 5);
            setRunTimer(false);
          }

          toggleTimer();

          setOtpToken(_rsDataOtpRequest.result.token);
          setOtpRefno(_rsDataOtpRequest.result.refno);
        } else {
          console.warn(_rsDataOtpRequest);
        }
      } else {
        console.warn(_rsDataOtpRequest);
      }
    });
  };

  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    // if (stateProfile.lineUid !== null && stateProfile.custCode !== null) {
    //   navigate("/profile");
    // }

    const { dataUser } = stateRegister;
    if (Object.keys(dataUser).length !== 0) {
      if (dataUser.mobileNo) {
        handleOtpRequest(dataUser.mobileNo);
      }
    } else {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pageRegisterOTP">
        <main>
          <div className="wrapContent">
            <HeaderPageTitle
              title="ยืนยันตัวตน"
              prevPage={PageRoute[stateRegister.prevPage]}
            />
            <form onSubmit={handleSubmit}>
              <section id="pRegisterMain">
                <div className="bRegister otp">
                  <div className="bTitle">
                    <div className="pageStep otp">
                      <div className="step active"></div>
                      <div className="step active"></div>
                      <div className="step"></div>
                    </div>
                    <p>เหลืออีกเพียงขั้นตอนเดียวก็จะเสร็จสิ้นการลงทะเบียน</p>
                  </div>
                  <div className="bOtp">
                    <img src={img_icoOtp} alt="" />
                    <h2>กรอกรหัสยืนยัน 6 หลัก</h2>
                    <p>
                      เราเพิ่งส่งรหัส OTP ไปให้ท่านทาง SMS <br />
                      ผ่านเบอร์{" "}
                      <span>
                        {stateRegister.dataUser.mobileNo
                          ? convertMobileFormat(
                              stateRegister.dataUser.mobileNo,
                              "-"
                            )
                          : ""}
                      </span>
                    </p>
                    <div className="bCountTime">
                      <span id="timer">
                        {minutes}:{seconds}
                      </span>
                    </div>
                    <div className="bFrom">
                      <div className="control-group otp">
                        <input
                          className="smsCode "
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onPaste={handlePasteOtp}
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode "
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode "
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode "
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode "
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                        <input
                          className="smsCode "
                          inputMode="numeric"
                          type="text"
                          maxLength="1"
                          size="1"
                          min="0"
                          max="9"
                          pattern="[0-9]{1}"
                          onInput={handleInputOtp}
                          onKeyUp={handleKeyupOtp}
                        />
                      </div>
                      <div className="bTextSend">
                        <p>
                          ยังไม่ได้รหัส?{" "}
                          <a
                            onClick={() =>
                              stateRegister.dataUser.mobileNo
                                ? handleOtpRequest(
                                    stateRegister.dataUser.mobileNo
                                  )
                                : console.warn(stateRegister.dataUser.mobileNo)
                            }
                          >
                            ส่งอีกครั้ง
                          </a>
                        </p>
                      </div>
                      <p className="tError"></p>
                    </div>
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button className="btn outline" onClick={() => goBack()}>
                    ย้อนกลับ
                  </button>
                  <button
                    className="btn btnConfirm btn-submit-otp"
                    disabled={otp.length < 6}
                  >
                    ต่อไป
                  </button>
                </div>
              </section>
            </form>
          </div>
          <ModalOtpSuccess open={isModalShow} onClose={handleCloseModal} />
        </main>
      </div>
    </>
  );
};

export default AuthOtp;
