import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";

import img_icoSuccess from "../../../assets/img/ci/success/ico-success.svg";

// import useTranslations from "../../../i18n/useTranslations";

const Welcome = () => {
  // const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  const setDataRegis = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  /* Navigate */
  const navigate = useNavigate();

  const handleGotoProfile = (e) => {
    e.preventDefault();
    // console.log("You clicked", e.currentTarget.dataset.page);
    const page = e.currentTarget.dataset.page;
    navigate("/" + page);
  };
  /* End Navigate */

  /* First event render */
  const [flow, setFlow] = useState(stateRegister.flow);

  useEffect(() => {
    // console.log("useEffect !", stateProfile);

    if (stateProfile.id === 0) {
      navigate("/");
    }

    setDataRegis({});
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pageRegisterSuccess">
        <main>
          <div className="wrapContent">
            <section id="pWelcomeMain">
              <div className="bText">
                <img src={img_icoSuccess} alt="" />
                <p>
                  {flow === "verify" ? "ยืนยันตัวตนสำเร็จ!" : "ลงทะเบียนสำเร็จ!"}
                  <br />
                  <span>{flow === "verify" ? "สามารถไปยังโปรไฟล์ของคุณได้เลย" : "ขอบคุณที่มาเป็นส่วนหนึ่งกับพวกเรา"}</span>
                </p>
              </div>
              <div className="bBtn">
                <a className="btn" onClick={handleGotoProfile} data-page="member">
                  ไปยังโปรไฟล์
                </a>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default Welcome;
