import { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

const ModalEditProfileSuccess = ({
  open = false,
  onClose = (editProfileSuccess) => {},
}) => {
  const [modal, setModal] = useState(null);

  const openModal = () => {
    modal.open();
  };

  const closeModal = (editProfileSuccess) => {
    modal.close();
    onClose(editProfileSuccess);
  };

  const listenerCloseModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModalEditProfileSuccess");
    btnCloseModal.addEventListener("click", () => {
      closeModal(true);
    });
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalEditConfirm"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modalQrCode useEffect2!! >>", modalQrCode, open);

    if (modal && open) {
      openModal();
      listenerCloseModal();
    }
  }, [modal, open]);

  return (
    <div className="bModal bCard" id="ModalEditConfirm">
      <div className="bInner">
        <div className="bDetail">
          <p>แก้ไขข้อมูลเรียบร้อย</p>
          <p className="detail">สามารถเช็คข้อมูลได้ที่หน้าโปรไฟล์เลย</p>
        </div>
        <div className="group-Btn">
          <div className="modlaBtn" id="btnCloseModalEditProfileSuccess">
            <a className="confirmBtn btnCloseModal" href="#">
              ปิด
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditProfileSuccess;
