import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";

import HeaderPageTitle from "../../../layouts/inc/HeaderPageTitle";

import {
  convertDateFormat,
  convertMobileFormat,
  convertIdCardFormat,
  breakKeyDownEnter,
} from "../../../services/helper";

// import useTranslations from "../../../i18n/useTranslations";

import img_icoTitleLogo from "../../../assets/img/ci/ico-title-logo.svg";

const AuthUserConfirm = () => {
  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  const stateProfile = useSelector((state) => state.profile);

  // const { t } = useTranslations(); // แปลภาษา

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("auth-confirm");

  const PageRoute = {
    "auth-user": "/auth-user",
    "auth-otp": "/auth-otp",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: {
      isAgreeAndCon: false,
      isPdpa: false,
    },
  });

  // console.log("isValid: ", isValid);
  const [formIsValid, setFormIsValid] = useState(false);

  const handleCheckOnChange = () => {
    const _field = getValues();
    let _invalidState = true,
      _invalidFields = [];

    for (const key in _field) {
      const val = _field[key];
      // console.log(key, typeof val, val);

      let _invalid = getFieldState(key).invalid;
      if (val === "" || val === false || val === null) {
        _invalid = true;
      }
      _invalidFields.push(_invalid);
    }

    _invalidState = _invalidFields.includes(true);

    if (_invalidState === true) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }

    // console.log("onChange", _invalidState);
  };

  useEffect(() => {
    // console.log(formIsValid);
  }, [formIsValid]);
  /* End Form */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // console.log('onSubmit >>', data);

    setDataRegis({
      ...stateRegister.dataUser,
      ...data,
    });

    goNext("auth-otp");
  };

  const setDataRegis = (newData = {}) => {
    const tmpObj = { dataUser: newData };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };
  /* End Handle click submit form */

  /* onClick privacy policy save form data temp  */
  const setTempDataRegis = (data) => {
    const updateData = {
      isAgreeAndCon: watch("isAgreeAndCon"),
      isPdpa: watch("isPdpa"),
    };
    // console.log("setTempDataRegis >>", updateData);
    setDataRegis({ ...stateRegister.dataUser, ...updateData });
  };
  /* End onClick privacy policy save form data temp */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    // if (stateProfile.lineUid !== null && stateProfile.custNo !== null) {
    //   navigate("/profile");
    // }

    const { dataUser } = stateRegister;

    if (Object.keys(dataUser).length !== 0) {
      setValue(
        "isAgreeAndCon",
        dataUser.isAgreeAndCon ? dataUser.isAgreeAndCon : ""
      );
      setValue("isPdpa", dataUser.isPdpa ? dataUser.isPdpa : "");

      let _chkFormIsValid = true;
      Object.entries(dataUser).forEach(([key, val]) => {
        // console.log(key, typeof val, val);
        if (val === "" || val === false || val === null) {
          _chkFormIsValid = false;
        }
      });

      if (_chkFormIsValid === true) {
        setFormIsValid(true);
      }
    } else {
      navigate("/");
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pageRegisterCheck">
        <main>
          <div className="wrapContent">
            <HeaderPageTitle title="ตรวจสอบข้อมูล" prevPage="/auth-user" />

            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pRegisterCheck">
                <div className="bRegister">
                  <div className="bTitle">
                    <img src={img_icoTitleLogo} alt="" />
                    <div className="pageStep">
                      <div className="step active"></div>
                      <div className="step active"></div>
                      <div className="step"></div>
                    </div>
                    <p>เหลืออีกเพียงขั้นตอนเดียวก็จะเสร็จสิ้นการลงทะเบียน</p>
                  </div>

                  <div className="bForm">
                    <div className="bRow">
                      <div className="control-group">
                        <div className="tTitle ">ชื่อ-นามสกุล</div>
                        <p>{stateRegister.dataUser.fullname}</p>
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="control-group">
                        <div className="tTitle ">เบอร์โทรศัพท์</div>
                        <p>
                          {convertMobileFormat(
                            stateRegister.dataUser.mobileNo,
                            "-"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="control-group">
                        <div className="tTitle ">หมายเลขบัตรประชาชน</div>
                        <p>
                          {convertIdCardFormat(
                            stateRegister.dataUser.idCard,
                            "-"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="control-group">
                        <div className="tTitle ">อีเมล</div>
                        <p>{stateRegister.dataUser.email ? stateRegister.dataUser.email : ""}</p>
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="control-group">
                        <div className="tTitle ">วัน / เดือน / ปีเกิด</div>
                        <p>
                          {convertDateFormat(
                            stateRegister.dataUser.birthDate,
                            "d/m/y"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="bRow">
                      <div className="control-group">
                        <div className="tTitle ">เพศ</div>
                        <p>
                          {stateRegister.dataUser.gender === "M"
                            ? "ชาย"
                            : stateRegister.dataUser.gender === "F"
                            ? "หญิง"
                            : "ไม่ระบุ"}
                        </p>
                      </div>
                    </div>

                    <div className="bRow">
                      <div className="control-group pdpa error">
                        <label className="control control--checkbox ">
                          <p className="">
                            ยอมรับ{" "}
                            <Link
                              to="/term-and-conditions"
                              onClick={() => {
                                setTempDataRegis();
                              }}
                            >
                              ข้อตกลงและเงื่อนไข
                            </Link>
                          </p>
                          <Controller
                            name="isAgreeAndCon"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `กรุณายอมรับเงื่อนไข`,
                            }}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="checkbox"
                                checked={watch("isAgreeAndCon")}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          <div className="control__indicator"></div>
                        </label>
                        {errors.isAgreeAndCon && (
                          <label htmlFor="" className="tError">
                            {errors.isAgreeAndCon.message}
                          </label>
                        )}
                        <label className="control control--checkbox">
                          <p>
                            ยอมรับ{" "}
                            <Link
                              to="/privacy-policy"
                              onClick={() => {
                                setTempDataRegis();
                              }}
                            >
                              นโยบายความเป็นส่วนตัว
                            </Link>
                          </p>
                          <Controller
                            name="isPdpa"
                            control={control}
                            defaultValue=""
                            rules={{
                              required: `กรุณายอมรับเงื่อนไข`,
                            }}
                            render={({ field }) => (
                              <input
                                {...field}
                                type="checkbox"
                                checked={watch("isPdpa")}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  handleCheckOnChange();
                                }}
                              />
                            )}
                          />
                          <div className="control__indicator"></div>
                        </label>
                        {errors.isPdpa && (
                          <label htmlFor="" className="tError">
                            {errors.isPdpa.message}
                          </label>
                        )}
                      </div>
                    </div>

                    <p className="tError">Text Error Main Form</p>
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn outline"
                    onClick={(e) => {
                      e.preventDefault();
                      goBack("auth-user");
                    }}
                  >
                    ย้อนกลับ
                  </button>
                  <button className="btn" disabled={formIsValid ? false : true}>
                    ต่อไป
                  </button>
                </div>
              </section>
            </form>
          </div>
        </main>
      </div>
    </>
  );
};

export default AuthUserConfirm;
