import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// import useTranslations from "../../i18n/useTranslations";

import HeaderPageTitle from "../../layouts/inc/HeaderPageTitle";

const TermAndConditions = () => {
  // const { t } = useTranslations(); // แปลภาษา

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  // useEffect(() => {
  //   console.log(t.lang);
  // }, []);

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pagePolicy">
        <main>
          <div className="wrapContent">
            <HeaderPageTitle title="ข้อตกลงและเงื่อนไข" prevPage="-1" />

            <section id="pPolicyMain">
              <div className="bPolicy">
                <h1>
                  ยินดีต้อนรับสู่
                  <br />
                  TPD Paypoint
                </h1>
                <div className="bText">
                  <p className="tTitle">
                    นโยบายและข้อตกลงเหล่านี้ มีไว้สำหรับ
                    <br />
                    การใช้งาน TPD Paypoint
                  </p>
                  <p>
                    โดยการเข้าถึงเว็บไซต์นี้
                    เราถือว่าคุณยอมรับข้อกําหนดและเงื่อนไขเหล่านี้อย่างครบถ้วน
                    คุณจะไม่สามารถใช้เว็บไซต์ของ TPD PayPoint ได้
                    หากคุณไม่ได้่กดยินยอมข้อกําหนดและเงื่อนไขทั้งหมดที่ระบุไว้ในหน้านี้
                    <br />
                    <br />
                    คําศัพท์ต่อไปนี้ใช้กับข้อกําหนดและเงื่อนไข,นโยบายส่วนบุคคล
                    และข้อจํากัดความรับผิดชอบ หรือข้อตกลงทั้งหมด: "ลูกค้า",
                    "คุณ" และ "ของคุณ" หมายถึงคุณ
                    บุคคลที่เข้าถึงเว็บไซต์นี้และยอมรับข้อกําหนดและเงื่อนไขของบริษัท
                    "บริษัท", "ทางเรา", "เรา", "ของเรา" และ "พวกเรา"
                    หมายถึงบริษัทของเรา "ฝ่าย" "ฝ่าย" หรือ "เรา"
                    หมายถึงทั้งลูกค้าและตัวเราเอง
                    <br />
                    <br />
                    ข้อกําหนดทั้งหมดอ้างถึงข้อเสนอ การยอมรับ
                    และการพิจารณาการชําระเงินที่จําเป็นในการดําเนินกระบวนการช่วยเหลือลูกค้าของเราในลักษณะที่เหมาะสมที่สุด
                    ไม่ว่าจะโดยการประชุมอย่างเป็นทางการในระยะเวลาที่กําหนด
                    หรือวิธีการอื่นใด
                    เพื่อวัตถุประสงค์ที่ชัดเจนในการตอบสนองความต้องการของลูกค้าในส่วนที่เกี่ยวกับการให้บริการ/ผลิตภัณฑ์ที่ระบุไว้ของบริษัท
                    ตามและภายใต้กฎหมายที่มีอยู่ของ การใช้คําศัพท์ข้างต้น
                  </p>
                </div>
              </div>
            </section>

            <section id="pActionBtn">
              <div className="bBtn">
                <button className="btn outline" onClick={() => goBack()}>
                  ย้อนกลับ
                </button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};
export default TermAndConditions;
