import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setRegister } from "../../../features/RegisterSlice";
import { PatternFormat } from "react-number-format";
import { searchCustomer } from "../../../services/Api/Module/Customer";

import ModalContext from "../../../contexts/ModalContext";

import Helper, {
  convertDateFormat,
  convertMobileFormat,
  convertIdCardFormat,
  breakKeyDownEnter,
} from "../../../services/helper";

import HeaderPageTitle from "../../../layouts/inc/HeaderPageTitle";

import ModalAuthCancel from "../../../components/modal/ModalAuthCancel";

// import useTranslations from "../../../i18n/useTranslations";

import img_icoTitleLogo from "../../../assets/img/ci/ico-title-logo.svg";

const AuthUser = () => {
  // const { t } = useTranslations(); // แปลภาษา

  const dispatch = useDispatch(); // ไว้อัพเดต state กลาง
  const stateRegister = useSelector((state) => state.register); // ไว้ดึง state
  // const stateProfile = useSelector((state) => state.profile);

  const { modalAlert } = useContext(ModalContext); // import modal

  /* Set state Register {currentPage} on goNext & goBack */
  const navigate = useNavigate();
  const [page, setPage] = useState("auth-user");

  const PageRoute = {
    home: "/",
    "auth-confirm": "/auth-confirm",
  };

  const setCurrentPage = (currentPage) => {
    const tmpObj = { currentPage: currentPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const setPrevPage = (prevPage) => {
    const tmpObj = { prevPage: prevPage };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const goBack = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };

  const goNext = (newPage) => {
    setPrevPage(page);
    setCurrentPage(newPage);
    navigate(PageRoute[newPage]);
  };
  /* End Set state Register {currentPage} on goNext & goBack */

  /* Form */
  const {
    control,
    setValue,
    handleSubmit,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      mobileNo: "",
      idCard: "",
    },
  });

  // console.log("isValid: ", isValid);

  /* End Form */

  /* Handle click submit form */
  const onSubmit = (data) => {
    // console.log("onSubmit >>", data);

    if (data) {
      let payload = {
        mobileNo: convertMobileFormat(data.mobileNo),
        idCard: convertIdCardFormat(data.idCard),
      };

      // console.log(payload);
      getSearchCustomer(payload).then((_rsData) => {
        // console.log("getSearchCustomer >>>", _rsData);

        if (_rsData.status === true && _rsData.result.length !== 0) {
          let _dataFrom = _rsData.result.data_from
              ? _rsData.result.data_from
              : null,
            _dataUser = {
              id: _rsData.result.id ? _rsData.result.id : null,
              fullname: _rsData.result.fullname,
              mobileNo: _rsData.result.mobileNo,
              idCard: _rsData.result.idCard,
              email: _rsData.result.email,
              birthDate: convertDateFormat(_rsData.result.birthDate),
              gender: _rsData.result.gender,
              isAgreeAndCon: false,
              isPdpa: false,
            };

          // if (_dataFrom !== null) {
          //   _dataUser = {
          //     ..._dataUser,
          //     branchCode: _rsData.result.branch_code,
          //     currentPoint: _rsData.result.current_point
          //       ? _rsData.result.current_point
          //       : 0,
          //     custNo: _rsData.result.cust_no,
          //     custStat: _rsData.result.cust_stat,
          //     custType: _rsData.result.cust_type,
          //     nationCode: _rsData.result.nation_code,
          //     totalBuy: _rsData.result.total_buy ? _rsData.result.total_buy : 0,
          //   };
          // }

          setDataRegis(_dataUser, _dataFrom);

          goNext("auth-confirm");
        } else {
          console.warn(_rsData);
        }
      });
    }
  };

  const setDataRegis = (newData = {}, dataFrom = null) => {
    const tmpObj = { dataUser: newData, dataFrom: dataFrom };
    dispatch(setRegister(tmpObj)); // setStateRedux ส่งค่าเก็บเข้า state กลาง
  };

  const getSearchCustomer = async (payload) => {
    let _return = {
      status: false,
      result: null,
    };

    try {
      const { data } = await searchCustomer({ params: payload });
      // console.log("getSearchCustomer resData >>", data);

      if (data.resultCode === 20200) {
        const resData = data.body;

        _return.status = true;
        _return.result = resData;
      } else {
        if (data.resultCode === 20204) {
          let _title = `ไม่พบข้อมูลสมาชิก`,
            _subTitle = ``,
            _content = `โปรดตรวจสอบข้อมูลอีกครั้ง`;

          modalAlert.info({
            open: true,
            title: _title,
            subTitle: _subTitle,
            content: _content,
          });
        }
      }
    } catch (e) {
      console.log("ERR getSearchCustomer >> ", e);
    }

    return _return;
  };
  /* End Handle click submit form */

  /* Modal auth cancel */
  const [isModalShow, setIsModalShow] = useState(false);

  const handleCloseModal = (authCancel) => {
    setIsModalShow(false);

    if (authCancel === true) {
      setDataRegis({});

      setTimeout(() => goBack("home"), 200);
    }
  };
  /* End Modal auth cancel */

  /* First event render */
  useEffect(() => {
    // console.log("useEffect !", stateRegister);

    // if (stateProfile.lineUid !== null && stateProfile.custNo !== null) {
    //   navigate("/profile");
    // }

    const { dataUser } = stateRegister;

    if (Object.keys(dataUser).length !== 0) {
      setValue("mobileNo", convertMobileFormat(dataUser.mobileNo, "-"), {
        shouldValidate: true,
      });
      setValue("idCard", convertIdCardFormat(dataUser.idCard, "-"), {
        shouldValidate: true,
      });
    }
  }, []);
  /* End First event render */

  return (
    <>
      <Helmet>
        <title>Paypoint</title>
        <meta name="title" content="Paypoint" />
        <meta name="description" content="" />
        <meta name="keyword" content="" />
        <meta property="og:title" content="Paypoint" />
        <meta property="og:description" content="" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Paypoint" />
      </Helmet>

      <div id="pageRegisterVerify">
        <main>
          <div className="wrapContent">
            <HeaderPageTitle title="ค้นหาทะเบียน" prevPage="/" />

            <form
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={(e) => breakKeyDownEnter(e)}
            >
              <section id="pVerifyMain">
                <div className="bVerify">
                  <div className="bTitle">
                    <img src={img_icoTitleLogo} alt="" />
                    <div className="pageStep">
                      <div className="step active"></div>
                      <div className="step"></div>
                      <div className="step"></div>
                    </div>
                    <p>ลงทะเบียนอีกเพียง 2 ขั้นตอนก็จะเสร็จสิ้น</p>
                  </div>
                  <div className="bForm">
                    <div className="bRow">
                      <div
                        className={`control-group validate ${
                          errors.mobileNo ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">หมายเลขโทรศัพท์</div>
                        <Controller
                          name="mobileNo"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกหมายเลขโทรศัพท์ของคุณ`,
                            pattern: {
                              value: /^\d{3}-\d{3}-\d{4}$/, // /^\d{10}$/,
                              message: `กรุณากรอกหมายเลขโทรศัพท์ของคุณให้ถูกต้อง`,
                            },
                          }}
                          render={({
                            field: { name, value, disabled, onChange },
                          }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              disabled={disabled}
                              onChange={onChange}
                              displayType="input"
                              format="###-###-####"
                              placeholder={`หมายเลขโทรศัพท์ของคุณ`}
                            />
                          )}
                        />
                        {errors.mobileNo && (
                          <label htmlFor="" className="tError">
                            {errors.mobileNo.message}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="bRow">
                      <div
                        className={`control-group validate ${
                          errors.idCard ? "error" : ""
                        }`}
                      >
                        <div className="tTitle">หมายเลขบัตรประชาชน</div>
                        <Controller
                          name="idCard"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: `กรุณากรอกหมายเลขบัตรประชาชนของคุณ`,
                            pattern: {
                              value: /^\d{1}-\d{4}-\d{5}-\d{2}-\d{1}$/, // /^\d{13}$/,
                              message: `กรุณากรอกหมายเลขบัตรประชาชนของคุณให้ถูกต้อง`,
                            },
                          }}
                          render={({ field: { name, value, onChange } }) => (
                            <PatternFormat
                              name={name}
                              value={value}
                              displayType="input"
                              format="#-####-#####-##-#"
                              placeholder={`หมายเลขบัตรประชาชนของคุณ`}
                              onChange={onChange}
                            />
                          )}
                        />
                        {errors.idCard && (
                          <label htmlFor="" className="tError">
                            {errors.idCard.message}
                          </label>
                        )}
                      </div>
                    </div>
                    {/* <p className="tError">Text Error Main Form</p> */}
                  </div>
                </div>
              </section>
              <section id="pActionBtn">
                <div className="bBtn">
                  <button
                    className="btn outline"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsModalShow(true);
                    }}
                  >
                    ย้อนกลับ
                  </button>
                  <button
                    className="btn"
                    type="submit"
                    disabled={isValid ? false : true}
                  >
                    ต่อไป
                  </button>
                </div>
              </section>
            </form>
          </div>
          <ModalAuthCancel
            open={isModalShow}
            onClose={(authCancel) => handleCloseModal(authCancel)}
          />
        </main>
      </div>
    </>
  );
};

export default AuthUser;
