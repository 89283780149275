import { get, post, put } from "../Config";

export function accessToken({ lineUid }) {
  return post({ url: `/api/sb/v1/customer/accessToken/${lineUid}`, timeOut: 20000 });
}
export function searchCustomer({ params }) {
  return get({ url: `/api/sb/v1/customer/search`, params });
}
export function survey({ params }) {
  return get({ url: `/api/sb/v1/survey`, params });
}
export function createCustomer({ data }) {
  return post({ url: `/api/sb/v1/customer/create`, data, timeOut: 20000 });
}
export function updateCustomerVerify({ data }) {
  return post({ url: `/api/sb/v1/customer/update`, data, timeOut: 20000 });
}
export function updateCustomerProfile({ data, custId }) {
  return put({ url: `/api/sb/v1/customer/update/${custId}`, data, timeOut: 20000 });
}
export function custProfile({ params, custId }) {
  return get({ url: `/api/sb/v1/customer/profile/${custId}`, params });
}

export function OtpRequest({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_request`, data, timeOut: 20000 });
}
export function OtpVerify({ data }) {
  return post({ url: `/api/sb/v1/tbs/otp_verify`, data, timeOut: 20000 });
}