import { useEffect, useState } from "react";

import jBox from "jbox";
import $ from "jquery";

const ModalAuthCancel = ({ open = false, onClose = (authCancel) => {} }) => {
  const [modal, setModal] = useState(null);

  const openModal = () => {
    modal.open();
  };

  const closeModal = (authCancel) => {
    modal.close();
    onClose(authCancel);
  };

  const listenerCloseModal = () => {
    const btnCloseModal = document.getElementById("btnCloseModal");
    btnCloseModal.addEventListener("click", () => {
      closeModal(false);
    });
  };

  const listenerAuthCancel = () => {
    const btnAuthCancel = document.getElementById("btnAuthCancel");
    btnAuthCancel.addEventListener("click", () => {
      closeModal(true);
    });
  };

  /* First event render */
  useEffect(() => {
    const initModal = new jBox("Modal", {
      closeButton: false,
      closeOnClick: false,
      minWidth: 325,
      maxWidth: 375,
      content: $("#ModalConfirm"),
    });
    setModal(initModal);

    return () => {
      initModal.destroy();
    };
  }, []);
  /* End First event render */

  useEffect(() => {
    // console.log("modalQrCode useEffect2!! >>", modalQrCode, open);

    if (modal && open) {
      openModal();
      listenerCloseModal();
      listenerAuthCancel();
    }
  }, [modal, open]);

  return (
    <div className="bModal bCard" id="ModalConfirm">
      <div className="bInner">
        <div className="bDetail">
          <p>ยกเลิกการค้นหา?</p>
          <p className="detail">
            หากกดยืนยัน ระบบจะไม่ทำการบันทึก
            <br />
            ข้อมูลที่คุณได้ทำการกรอกล่าสุด
          </p>
        </div>
        <div className="group-Btn">
          <div className="modlaBtn" id="btnAuthCancel">
            <a className="confirmBtn btnCloseModal">ยืนยัน</a>
          </div>
          <div className="modlaBtn outline" id="btnCloseModal">
            <a className="confirmBtn btnCloseModal">ยกเลิก</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAuthCancel;
